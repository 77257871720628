import { map, filter, findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  travelersList: [],
  allTravelersList: [],
  bckTravelersList: [],
  travelersListFilteredByCity: []
};

const slice = createSlice({
  name: 'traveler',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getTravelersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE GREETERS
    deleteTraveler(state, action) {
      const deleteTraveler = filter(state.travelersList, (user) => user.id !== action.payload);
      state.travelersList = deleteTraveler;
    },

    // UPDATE TRAVELER STATUS
    updateTravelerStatus(state, action) {
      const index = findIndex(state.travelersList, { _id: action.payload.id });
      state.travelersList[index].isActive = action.payload.isActive;
    },

    // UPDATE TRAVELER IDENTITY VERIFICATION
    updateTravelerIdentityVerification(state, action) {
      const index = findIndex(state.travelersList, { _id: action.payload.id });

      if (state.travelersList[index].verification == null) {
        state.travelersList[index].verification = {};
      }

      state.travelersList[index].verification.identity = action.payload.identity;
    },

    // UPDATE TRAVELER EMAIL VERIFICATION
    updateTravelerEmailVerification(state, action) {
      const index = findIndex(state.travelersList, { _id: action.payload.id });

      if (state.travelersList[index].verification == null) {
        state.travelersList[index].verification = {};
      }

      state.travelersList[index].verification.email = action.payload.email;
    },

    // UPDATE TRAVELER PHONE VERIFICATION
    updateTravelerPhoneVerification(state, action) {
      const index = findIndex(state.travelersList, { _id: action.payload.id });

      if (state.travelersList[index].verification == null) {
        state.travelersList[index].verification = {};
      }

      state.travelersList[index].verification.phone = action.payload.phone;
    },

    // FILTER TRAVELER LIST BY VERIFICATION COMPLETED
    filterTravelerListByVerificationCompleted(state, action) {
      let onlyVerified;

      if (state.bckTravelersList.length === 0) {
        onlyVerified = state.travelersList?.data.filter(
          (user) => user.verification?.identity && user.verification?.email && user.verification?.phone
        );

        state.bckTravelersList = state.travelersList.data;
      } else {
        onlyVerified = state.bckTravelersList.filter(
          (user) => user.verification?.identity && user.verification?.email && user.verification?.phone
        );
      }

      state.travelersList.data = onlyVerified;
    },

    // FILTER TRAVELER LIST BY VERIFICATION NOT COMPLETED
    filterTravelerListByVerificationNotCompleted(state, action) {
      let onlyNotVerified;

      if (state.bckTravelersList.length === 0) {
        onlyNotVerified = state.travelersList.data.filter(
          (user) => !user.verification?.identity || !user.verification?.email || !user.verification?.phone
        );

        state.bckTravelersList = state.travelersList.data;
      } else {
        onlyNotVerified = state.bckTravelersList.filter(
          (user) => !user.verification?.identity || !user.verification?.email || !user.verification?.phone
        );
      }

      state.travelersList.data = onlyNotVerified;
    },

    // RESET FILTER TRAVELER LIST
    resetFilterTravelerList(state, action) {
      state.travelersList = state.bckTravelersList;
    },

    // FILTER TRAVELER LIST BY CITY NAME
    filterTravelerListByCityName(state, action) {
      let byCityName;

      if (state.bckTravelersList.length === 0) {
        byCityName = state.travelersList.filter(
          (user) =>
            user.profile?.city != null && user.profile?.city.toLowerCase().includes(action.payload.city.toLowerCase())
        );

        state.bckTravelersList = state.travelersList;
      } else {
        byCityName = state.bckTravelersList.filter(
          (user) =>
            user.profile?.city != null && user.profile?.city.toLowerCase().includes(action.payload.city.toLowerCase())
        );
      }

      state.travelersList = byCityName;
    },

    // FILTER TRAVELER LIST BY COUNTRY NAME
    filterTravelerListByCountryName(state, action) {
      let byCountryName;

      if (state.bckTravelersList.length === 0) {
        byCountryName = state.travelersList.filter(
          (user) =>
            user.profile?.country != null &&
            user.profile?.country.toLowerCase().includes(action.payload.country.toLowerCase())
        );

        state.bckTravelersList = state.travelersList;
      } else {
        byCountryName = state.bckTravelersList.filter(
          (user) =>
            user.profile?.country != null &&
            user.profile?.country.toLowerCase().includes(action.payload.country.toLowerCase())
        );
      }

      state.travelersList = byCountryName;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getTravelersListSuccess(state, action) {
      state.isLoading = false;
      state.travelersList = action.payload;
    },

    // GET MANAGE USERS
    getAllTravelersListSuccess(state, action) {
      state.isLoading = false;
      state.allTravelersList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleFollow,
  deleteTraveler,
  updateTravelerStatus,
  updateTravelerEmailVerification,
  updateTravelerIdentityVerification,
  updateTravelerPhoneVerification,
  filterTravelerListByVerificationCompleted,
  filterTravelerListByVerificationNotCompleted,
  resetFilterTravelerList,
  filterTravelerListByCityName,
  filterTravelerListByCountryName
} = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTravelersList(limit = 5, offset = 0, orderBy = 'createdAt', sort = 'desc', search = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/users/travelers?limit=${limit}&offset=${offset}&orderBy=${orderBy}&sort=${sort}&search=${search}`
      );
      dispatch(slice.actions.getTravelersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllTravelersList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/travelers?limit=0&offset=0`);
      dispatch(slice.actions.getAllTravelersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getTravelersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
