// material
import { styled } from '@material-ui/core/styles';
import { Card, Container } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Greether CRM">
      <MHidden width="mdDown">
        <SectionStyle>
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src="/static/auth/bg_login.png"
            alt="Background Login"
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <img src="/static/auth/logo-greether.png" alt="Logo Greether" />
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
