import { map, filter, findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  adminsList: [],
  bckAdminsList: [],
  selectedAdmin: null
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SELECTED ADMIN
    setSelectedAdmin(state, action) {
      state.selectedAdmin = state.adminsList.find((admin) => admin._id === action.payload);
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getAdminsSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE ADMINS
    deleteAdmin(state, action) {
      const deleteAdmin = filter(state.adminsList, (user) => user.id !== action.payload);
      state.adminsList = deleteAdmin;
    },

    // UPDATE ADMIN STATUS
    updateAdminStatus(state, action) {
      const index = findIndex(state.adminsList, { _id: action.payload.id });
      state.adminsList[index].isActive = action.payload.isActive;
    },

    // UPDATE ADMIN IDENTITY VERIFICATION
    updateAdminIdentityVerification(state, action) {
      const index = findIndex(state.adminsList, { _id: action.payload.id });

      if (state.adminsList[index].verification == null) {
        state.adminsList[index].verification = {};
      }

      state.adminsList[index].verification.identity = action.payload.identity;
    },

    // UPDATE ADMIN EMAIL VERIFICATION
    updateAdminEmailVerification(state, action) {
      const index = findIndex(state.adminsList, { _id: action.payload.id });

      if (state.adminsList[index].verification == null) {
        state.adminsList[index].verification = {};
      }

      state.adminsList[index].verification.email = action.payload.email;
    },

    // UPDATE ADMIN PHONE VERIFICATION
    updateAdminPhoneVerification(state, action) {
      const index = findIndex(state.adminsList, { _id: action.payload.id });

      if (state.adminsList[index].verification == null) {
        state.adminsList[index].verification = {};
      }

      state.adminsList[index].verification.phone = action.payload.phone;
    },

    // FILTER ADMIN LIST BY VERIFICATION COMPLETED
    filterAdminListByVerificationCompleted(state, action) {
      let onlyVerified;

      if (state.bckAdminsList.length === 0) {
        onlyVerified = state.adminsList.filter(
          (user) => user.verification?.identity && user.verification?.email && user.verification?.phone
        );

        state.bckAdminsList = state.adminsList;
      } else {
        onlyVerified = state.bckAdminsList.filter(
          (user) => user.verification?.identity && user.verification?.email && user.verification?.phone
        );
      }

      state.adminsList = onlyVerified;
    },

    // FILTER ADMIN LIST BY VERIFICATION NOT COMPLETED
    filterAdminListByVerificationNotCompleted(state, action) {
      let onlyNotVerified;

      if (state.bckAdminsList.length === 0) {
        onlyNotVerified = state.adminsList.filter(
          (user) => !user.verification?.identity || !user.verification?.email || !user.verification?.phone
        );

        state.bckAdminsList = state.adminsList;
      } else {
        onlyNotVerified = state.bckAdminsList.filter(
          (user) => !user.verification?.identity || !user.verification?.email || !user.verification?.phone
        );
      }

      state.adminsList = onlyNotVerified;
    },

    // RESET FILTER ADMIN LIST
    resetFilterAdminList(state, action) {
      state.adminsList = state.bckAdminsList;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getAdminsListSuccess(state, action) {
      state.isLoading = false;
      state.adminsList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleFollow,
  deleteAdmin,
  updateAdminStatus,
  updateAdminIdentityVerification,
  updateAdminEmailVerification,
  updateAdminPhoneVerification,
  filterAdminListByVerificationCompleted,
  resetFilterAdminList,
  filterAdminListByVerificationNotCompleted,
  setSelectedAdmin
} = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAdminsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/users/admins');
      dispatch(slice.actions.getAdminsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getAdminsSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const adminSelector = (state) => state.admin;
