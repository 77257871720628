import { map, filter, findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  greetersList: [],
  allGreetersList: [],
  bckGreetersList: []
};

const slice = createSlice({
  name: 'greeter',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getGreetersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE GREETERS
    deleteGreeter(state, action) {
      const deleteGreeter = filter(state.greetersList, (user) => user.id !== action.payload);
      state.greetersList = deleteGreeter;
    },

    // UPDATE GREETER STATUS
    updateGreeterStatus(state, action) {
      const index = findIndex(state.greetersList, { _id: action.payload.id });
      state.greetersList[index].isActive = action.payload.isActive;
    },

    // UPDATE GREETER IDENTITY VERIFICATION
    updateGreeterIdentityVerification(state, action) {
      const index = findIndex(state.greetersList, { _id: action.payload.id });

      if (state.greetersList[index].verification == null) {
        state.greetersList[index].verification = {};
      }

      state.greetersList[index].verification.identity = action.payload.identity;
    },

    // UPDATE GREETER EMAIL VERIFICATION
    updateGreeterEmailVerification(state, action) {
      const index = findIndex(state.greetersList, { _id: action.payload.id });

      if (state.greetersList[index].verification == null) {
        state.greetersList[index].verification = {};
      }

      state.greetersList[index].verification.email = action.payload.email;
    },

    // UPDATE GREETER PHONE VERIFICATION
    updateGreeterPhoneVerification(state, action) {
      const index = findIndex(state.greetersList, { _id: action.payload.id });

      if (state.greetersList[index].verification == null) {
        state.greetersList[index].verification = {};
      }

      state.greetersList[index].verification.phone = action.payload.phone;
    },

    // FILTER GREETER LIST BY VERIFICATION COMPLETED
    filterGreeterListByVerificationCompleted(state, action) {
      let onlyVerified;

      if (state.bckGreetersList.length === 0) {
        onlyVerified = state.greetersList.data.filter(
          (user) => user.verification?.identity && user.verification?.email && user.verification?.phone
        );

        state.bckGreetersList = state.greetersList.data;
      } else {
        onlyVerified = state.bckGreetersList.filter(
          (user) => user.verification?.identity && user.verification?.email && user.verification?.phone
        );
      }

      state.greetersList.data = onlyVerified;
    },

    // FILTER GREETER LIST BY VERIFICATION NOT COMPLETED
    filterGreeterListByVerificationNotCompleted(state, action) {
      let onlyNotVerified;

      if (state.bckGreetersList.length === 0) {
        onlyNotVerified = state.greetersList.data.filter(
          (user) => !user.verification?.identity || !user.verification?.email || !user.verification?.phone
        );

        state.bckGreetersList = state.greetersList.data;
      } else {
        onlyNotVerified = state.bckGreetersList.filter(
          (user) => !user.verification?.identity || !user.verification?.email || !user.verification?.phone
        );
      }

      state.greetersList.data = onlyNotVerified;
    },

    // RESET FILTER GREETER LIST
    resetFilterGreeterList(state, action) {
      state.greetersList.data = state.bckGreetersList;
    },

    // FILTER GREETER LIST BY COUNTRY NAME
    filterGreeterListByCountryName(state, action) {
      let byCountryName;

      if (state.bckGreetersList.length === 0) {
        byCountryName = state.greetersList.filter(
          (user) =>
            user.profile?.country != null &&
            user.profile?.country.toLowerCase().includes(action.payload.country.toLowerCase())
        );

        state.bckGreetersList = state.greetersList;
      } else {
        byCountryName = state.bckGreetersList.filter(
          (user) =>
            user.profile?.country != null &&
            user.profile?.country.toLowerCase().includes(action.payload.country.toLowerCase())
        );
      }

      state.greetersList = byCountryName;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getGreetersListSuccess(state, action) {
      state.isLoading = false;
      state.greetersList = action.payload;
    },

    // GET MANAGE USERS
    getAllGreetersListSuccess(state, action) {
      state.isLoading = false;
      state.allGreetersList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleFollow,
  deleteGreeter,
  updateGreeterStatus,
  updateGreeterEmailVerification,
  updateGreeterIdentityVerification,
  updateGreeterPhoneVerification,
  filterGreeterListByVerificationCompleted,
  filterGreeterListByVerificationNotCompleted,
  resetFilterGreeterList,
  filterGreeterListByCityName,
  filterGreeterListByCountryName
} = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGreetersList(limit = 5, offset = 0, orderBy = 'createdAt', sort = 'desc', search = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/users/greeters?limit=${limit}&offset=${offset}&orderBy=${orderBy}&sort=${sort}&search=${search}`
      );
      dispatch(slice.actions.getGreetersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllGreetersList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/greeters?limit=0&offset=0`);
      dispatch(slice.actions.getAllGreetersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getGreetersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
