import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import adminReducer from './slices/admin';
import greeterReducer from './slices/greeter';
import cityReducer from './slices/city';
import countryReducer from './slices/country';
import destinationReducer from './slices/destination';
import experienceReducer from './slices/experience';
import travelerReducer from './slices/traveler';
import homeReducer from './slices/home';
import interactionReducer from './slices/interaction';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  admin: adminReducer,
  greeter: greeterReducer,
  city: cityReducer,
  country: countryReducer,
  destination: destinationReducer,
  experience: experienceReducer,
  traveler: travelerReducer,
  home: homeReducer,
  interaction: interactionReducer
});

export { rootPersistConfig, rootReducer };
